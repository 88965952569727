import  { Component } from "react";
import '../styles/Forgotpassword/style.css';
import { AiOutlineArrowRight } from "react-icons/ai";
class SentSuccessfully extends Component {
    render() {
        if (localStorage.getItem("token")) 
        {
            this.props.history.goBack();
        }
        return (
            <section class="mail-seccess section">
                <div style={{height:"100vh"}}>
                    <div class="container">
                                <div class="success-inner">
                                    <h1><i class="fa fa-envelope"></i><span>Your Mail Sent Successfully!</span></h1>
                                    <p>An email has been sent to you providing instructions to reset your password</p>
                                    <a href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(process.env.REACT_APP_MAIN_DOMAIN)}`} class="btn btn-primary btn-lg" >SIGN IN
                                        <AiOutlineArrowRight fontSize={20} style={{paddingLeft:"4px",paddingBottom:"2px"}}/>
                                    </a>
                                </div>
                    </div>
                </div>
            </section>
        );
      }
}
export default SentSuccessfully;