import React from "react";
import Joi from "joi-browser";
import { ToastContainer } from "react-toastify";
import Form from "./common/form";
import { createreply } from "../services/replyCreateService";

class PostReply extends Form {
  state = {
    data: {
      comment: "",
    },
    errors: { comment: "" },
    check: false,
  };
  schema = {
    comment: Joi.string().required().min(5).label("Comment"),
  };
  doSubmit = async () => {
    try {
      const { user } = this.props;
      if (user) {
        const { data } = this.state;
        await createreply(data, this.props.id);
        window.location = `/post/${this.props.id}`;
      } else {
        window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(window.location.href)}`;
      }
    } catch (ex) {}
  };
  render() {
    const { data, errors, check } = this.state;
    const { user } = this.props;
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="container col-lg-10 shadow-lg p-3 mt-3 bg-body rounded">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="description">Post Reply</label>
              <textarea
                className="border border-primary form-control"
                value={data.comment}
                onChange={this.handleChange}
                name="comment"
                type="comment"
                id="comment"
              />
              {this.state.check && errors.comment && (
                <div
                  style={{
                    color: "#dc3545",
                  }}
                >
                  {errors.comment}
                </div>
              )}
              <div className="text-center">
                <button
                  className="btn btn-outline-primary mt-4"
                  onClick={() => this.setState({ check: true })}
                >
                  Post Reply
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default PostReply;
