import React from "react";
import { Link } from "react-router-dom";
import Avatar, { ConfigProvider } from "react-avatar";
import "../../styles/forum/forum.css";
const Posts = (props) => {
  const { posts } = props;
  console.log(posts);
  return (
    <ConfigProvider colors={["brown", "green", "blue"]}>
      <div className="list-group">
        {posts.length>0 && posts.map((post) => (
          <Link
            className="list-group-item list-group-item-action flex-column align-items-start border
          shadow-lg rounded mb-2"
            to={`/post/${post._id}`}
          >
            <div
              className="d-flex w-100 justify-content-between"
              key={post._id}
            >
              <h4 className="mb-1">
                <b>{post.title}</b>
              </h4>
            </div>
            <Avatar
              name={post.author?.name}
              size="25px"
              round="15px"
            />
            <small className="ml-2">Created by {post.author?.name}</small>
            <br />
            <small className="overflow-hidden summary_desc">{post.description}</small>
            <div className="mt-1">
              Related Topics:
              {post.tags.map((tag) => (
                <span className="badge badge-success m-1 p-2 bg-primary">{tag?.name}</span>
              ))}
              <h6 className="mt-2">
                {post.upvotes.length} Likes | {post.views} Views
              </h6>
            </div>
          </Link>
        ))}
      </div>
    </ConfigProvider>
  );
};

export default Posts;