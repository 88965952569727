import React, { useEffect, useState } from 'react';
import PostsList from './postsList';
import FooterTwo from '../footer/FooterTwo';
import Image from './image__introduction';
import { client } from '../../contenful';
import MyVerticallyCenteredModal from '../Modal';
import Pack from './PackDetail';
export default function Consulting() {
  const [content, setContent] = useState({
    loading: true,
  });
  const [show, setShow] = useState(false);
  console.log(content);
  const setStateshow = () => {
    return setShow(!show);
  };
  useEffect(() => {
    client.getEntries({ content_type: 'businessConsultingPage', include: 10,locale: localStorage.getItem('language') }).then((raw) => {
      let content = raw.items[0].fields;
      console.log(content)
      let HeaderImage = content.headerImage.fields.file.url;
      let StandardPack= content.standardPack.map((item) => {
        let { title, summary, tags } = item.fields;
        let id = item.sys.id;
        let tagsName = tags.map((tag) => tag.fields.name);
        return { id, title, summary, tagsName };
      });
      StandardPack.push({})
      let PremiumPack= content.premiumPack.map((item) => {
        let { title, summary, tags } = item.fields;
        let id = item.sys.id;
        let tagsName = tags.map((tag) => tag.fields.name);
        return { id, title, summary, tagsName };
      });
      PremiumPack.push({})
      setContent({
        loading: false,
        HeaderImage,
        StandardPack,
        PremiumPack,
      });
    });
  }, []);
  return !content.loading ? (
    <React.Fragment>
      <div style={{paddingBottom:"80px",background:"white"}}>
        <Image image={content.HeaderImage} width="100%" />
        <PostsList />
        <MyVerticallyCenteredModal show={show} onHide={() => setStateshow()} />
        <Heading heading={localStorage.getItem('language') === "en-US"?"Consulting":"Dịch vụ tư vấn"}/>
        <Pack heading={localStorage.getItem('language') === "en-US"?"Standard Pack":"Gói Tiêu Chuẩn"} posts={content.StandardPack} index={0}/>
        <Pack heading={localStorage.getItem('language') === "en-US"?"Premium Pack":"Gói Dành Cho Doanh Nghiệp"} posts={content.PremiumPack} index={1} onShowModal={setStateshow}/>
      </div>
      <FooterTwo></FooterTwo>
    </React.Fragment>
  ) : null;
}
function Heading(props)
{
  return(
    <div className="container-fluid mb-2">
      <h5 className="text-uppercase col-12">
        {props.heading}
      </h5>
    </div>
  )
}