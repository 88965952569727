export const calculateTimesSinceDate = (fromDate, toDate) => {
  const timeDiffInSecond = Math.floor(
    (toDate.getTime() - fromDate.getTime()) / 1000
  );
  const timeDiffInMinute = Math.floor(timeDiffInSecond / 60);
  const timeDiffInHour = Math.floor(timeDiffInSecond / 60 / 60);
  const timeDiffInDay = Math.floor(timeDiffInSecond / 60 / 60 / 24);
  const timeDiffInMonth = Math.floor(timeDiffInSecond / 60 / 60 / 24 / 30);
  const timeDiffInYear = Math.floor(timeDiffInSecond / 60 / 60 / 24 / 30 / 12);

  if (timeDiffInSecond < 60) {
    if (timeDiffInSecond <= 1) return "now";
    else return timeDiffInSecond + " seconds ago";
  }

  if (timeDiffInMinute < 60) {
    if (timeDiffInMinute === 1) return timeDiffInMinute + " minute ago";
    else return timeDiffInMinute + " minutes ago";
  }

  if (timeDiffInHour < 24) {
    if (timeDiffInHour === 1) return timeDiffInHour + " hour ago";
    else return timeDiffInHour + " hours ago";
  }

  if (timeDiffInDay < 30) {
    if (timeDiffInDay === 1) return timeDiffInDay + " day ago";
    else return timeDiffInDay + " days ago";
  }

  if (timeDiffInMonth < 12) {
    if (timeDiffInMonth === 1) return timeDiffInMonth + " month ago";
    else return timeDiffInMonth + " months ago";
  }

  if (timeDiffInYear === 1) return timeDiffInYear + " year ago";
  else return timeDiffInYear + " years ago";
};
