import React from "react";
import { useState } from "react";
const ListGroup = (props) => {
  const onChangeItem = (event) => {
    event.preventDefault();
    const selectedItem = items.find(item => item._id === event.target.value);
    onTagSelect(selectedItem);
  };
  const { items, selectedTag, onTagSelect } = props;
  return window.innerWidth>=576?(
    <ul className="list-group">
      {items.map((item) => (
        <li
          key={item._id}
          className={
            item === selectedTag ? "list-group-item active font-weight-bold" : "list-group-item font-weight-bold"
          }
          onClick={() => onTagSelect(item)}
        >
          {item.name}
        </li>
      ))}
    </ul>
  ):(
    <select className="form-control list-group col-12" onChange={onChangeItem}>
      {items.map((item) => (
        <option
          key={item._id}
          value={item._id}
          className={
            item === selectedTag ? "list-group-item active font-weight-bold" : "list-group-item font-weight-bold"
          }
        >
          {item.name}
        </option>
      ))}
    </select>
  )
};

ListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id",
};

export default ListGroup;
