import { Component } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from '../../assets/img/logo.png';
import '../../styles/navbar/navbar.css';
import { includes } from 'lodash';

class Navbar extends Component {
  state = { searchQuery: '', clicked: false, menu: window.location.pathname };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}/search/?search=${this.state.searchQuery}`;
  };

  handleLogOut = () => {
    localStorage.removeItem('token');
    window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}/removetoken/${encodeURIComponent(
      window.location.href
    )}`;
  };
  handleChangeLanguage = () => {
    if (localStorage.getItem('language') == 'vi-VN') {
      localStorage.removeItem('language');
      localStorage.setItem('language', 'en-US');
      window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}/setlanguage/${encodeURIComponent(
        window.location.href,
      )}/en-US`;
    } else {
      localStorage.removeItem('language');
      localStorage.setItem('language', 'vi-VN');
      window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}/setlanguage/${encodeURIComponent(
        window.location.href,
      )}/vi-VN`;
    }
  };
  render() {
    const Menu = [
      {
        to: '/',
        title: localStorage.getItem('language') === "en-US"?'Home':'Trang chủ',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/about',
        title: localStorage.getItem('language') === "en-US"?'About Us':'Giới thiệu',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/business-representative',
        title: localStorage.getItem('language') === "en-US"?'Business Representative':'Đại diện kinh doanh',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/export-to-au',
        title: localStorage.getItem('language') === "en-US"?'Export To Australia':'Xuất khẩu đến Úc',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: '/export-to-vn',
        title: localStorage.getItem('language') === "en-US"?'Export To Viet Nam':'Xuất khẩu đến Việt Nam',
        domain: `${process.env.REACT_APP_MAIN_DOMAIN}`,
      },
      {
        to: `/forum`,
        title: localStorage.getItem('language') === "en-US"?'Forum':'Diễn đàn',
        domain: `${process.env.REACT_APP_SUB_DOMAIN}`,
      },
      {
        to: `/consulting`,
        title: localStorage.getItem('language') === "en-US"?'Consulting':'Tư vấn',
        domain: `${process.env.REACT_APP_SUB_DOMAIN}`,
      },
    ];
    let url = window.location.href;
    if (
      url == `${process.env.REACT_APP_SUB_DOMAIN}/users/forgotpassword/emailsent` ||
      url == `${process.env.REACT_APP_SUB_DOMAIN}/users/forgotpassword` ||
      url == `${process.env.REACT_APP_SUB_DOMAIN}/users/register` ||
      url == `${process.env.REACT_APP_SUB_DOMAIN}/users/resetpassword/successfully` ||
      url.includes(`${process.env.REACT_APP_SUB_DOMAIN}/users/resetpassword`)
    ) {
      url = process.env.REACT_APP_MAIN_DOMAIN;
    }
    const encodedId = encodeURIComponent(url);
    return (
      <>
        <nav className="d-flex pl-4 pb-2 header">
          <div className="d-flex w-100 justify-content-between pt-1">
            <div className="d-flex">
              <a href={process.env.REACT_APP_MAIN_DOMAIN}>
                {' '}
                <img width={100} height={80} src={logo} alt="Digital Agency" />
              </a>
              <ul id="navbar" className={this.state.clicked ? '#navbar active' : '#navbar'}>
                {Menu.map((item, index) => {
                  return (
                    <li key={index} className="nav-item">
                      <a href={item.domain + item.to} className={item.to == this.state.menu && 'active'}>
                        {item.title}
                      </a>
                    </li>
                  );
                })}
                <li className="nav-item">
                  <form
                    className="mainmenu__form"
                    style={{
                      paddingTop: '1.02rem',
                    }}
                    onSubmit={this.handleSubmit}
                  >
                    <input
                      style={{
                        width: '8rem',
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={this.state.searchQuery}
                      onChange={this.handleChange}
                    />
                  </form>
                  <li
                    onClick={this.handleChangeLanguage}
                    style={{ cursor: 'pointer', listStyle: 'none', marginTop: '1rem' }}
                    className="logo__navbar__mobile"
                  >
                    <img
                      src={`${process.env.REACT_APP_SUB_DOMAIN}/assets/img/${
                        localStorage.getItem('language') == 'vi-VN' ? 'au' : 'vi'
                      }.png`}
                      style={{ height: '40px', width: '60px', borderRadius: '4px' }}
                      alt='Images'
                    ></img>
                  </li>
                </li>
                {localStorage.getItem('token') ? (
                  <li
                    className="nav-item login-btn-responsive"
                  >
                    <span className="nav-item close-btn">
                      <div id="mobile" onClick={this.handleClick}>
                        <i id="bar">
                          {/* {this.state.clicked ? <AiOutlineClose /> : <AiOutlineMenu />} */}
                          {this.state.clicked && <AiOutlineClose />}
                        </i>
                      </div>
                    </span>
                    <a onClick={this.handleLogOut}>
                      <button type="button" className="btn btn-outline-dark">
                        <b>{localStorage.getItem('language')==='en-US'?"Log Out":"Đăng Xuất"}</b>
                      </button>
                    </a>
                  </li>
                ) : (
                  <li className="nav-item login-btn-responsive" >
                    <span className="nav-item close-btn">
                      <div id="mobile" onClick={this.handleClick}>
                        <i id="bar">{this.state.clicked && <AiOutlineClose />}</i>
                      </div>
                    </span>
                    <a href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodedId}`}>
                      <button type="button" className="btn btn-outline-dark">
                        <b>{localStorage.getItem('language')==='en-US'?"Sign In":"Đăng Nhập"}</b>
                      </button>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <ul className="d-flex align-items-center mt-2">
              <li
                onClick={this.handleChangeLanguage}
                style={{ cursor: 'pointer', listStyle: 'none' }}
                className="logo__navbar__desktop"
              >
                <img
                  src={`${process.env.REACT_APP_SUB_DOMAIN}/assets/img/${
                    localStorage.getItem('language') == 'vi-VN' ? 'au' : 'vi'
                  }.png`}
                  style={{ height: '32px', width: '48px', borderRadius: '4px' }}
                ></img>
              </li>
              {localStorage.getItem('token') ? (
                <li
                  className="nav-item login-btn"
                  style={{
                    listStyle: 'none',
                  }}
                >
                  <a onClick={this.handleLogOut}>
                    <button type="button" className="btn btn-outline-dark">
                      <b>{localStorage.getItem('language')==='en-US'?"Log Out":"Đăng Xuất"}</b>
                    </button>
                  </a>
                </li>
              ) : (
                <>
                  <li className="nav-item login-btn">
                    <a href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodedId}`}>
                      <button type="button" className="btn btn-outline-dark">
                        <b>{localStorage.getItem('language')==='en-US'?"Sign In":"Đăng Nhập"}</b>
                      </button>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div id="mobile" onClick={this.handleClick}>
            <i id="bar">
              {/* {this.state.clicked ? <AiOutlineClose /> : <AiOutlineMenu />} */}
              {!this.state.clicked && <AiOutlineMenu />}
            </i>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
