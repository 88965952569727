import React from "react";
import Input from "./common/input";
import Form from "./common/form";
import Joi from "joi-browser";
import { Redirect } from "react-router-dom";
import * as userService from "../services/userService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/react-toastify.esm";

class Register extends Form {
  state = {
    data: {
      username: "",
      email: "",
      password: "",
      password2: "",
      name: "",
      nationality: "",
      phone_number: "",
    },
    errors: {
      username: "",
      email: "",
      password: "",
      password2: "",
      name: "",
      nationality: "",
      phone_number: "",
    },
  };
  schema = {
    name: Joi.string().required().label("Full Name"),
    username: Joi.string().required().label("Username"),
    email: Joi.string().required().label("Email ID"),
    password: Joi.string().required().label("Password (At least 5 characters)"),
    password2: Joi.string().required().label("Confirm Password"),
    nationality: Joi.string().required().label("Nationality"),
    phone_number: Joi.string().required().label("Phone Number"),
  };
  doSubmit = async () => {
    try {
      const response = await userService.register(this.state.data);
      localStorage.setItem("token", response.headers["x-auth-token"]);
      const token = response.headers["x-auth-token"];
      window.location.href = `${
        process.env.REACT_APP_MAIN_DOMAIN
      }/settoken/${token}/${encodeURIComponent(
        process.env.REACT_APP_MAIN_DOMAIN
      )}`;
    } catch (ex) {
      if (ex.response) {
        toast.error(ex.response.data);
      }
    }
  };
  render() {
    const { data, errors } = this.state;
    if (localStorage.getItem("token")) {
      return <Redirect to="/forum" />;
    }
    return (
      <React.Fragment>
        <ToastContainer />
        <div
          className="container-fluid col-10 col-lg-8 mt-3 mb-4"
          style={{
            border: "0.5px solid gray",
            borderRadius: "10px",
          }}
        >
          <h1 className="mt-4 mb-4">
            <b>Register</b>
          </h1>
          <form onSubmit={this.handleSubmit} className="row">
            <div className="col-6">
              <Input
                value={data.name}
                onChange={this.handleChange}
                required={true}
                label="Name"
                name="name"
                type="text"
                error={errors.name}
              />
            </div>
            <div className="col-6">
              <Input
                className="col-6 ml-5"
                name="username"
                value={data.username}
                required={true}
                label="Username"
                type="text"
                onChange={this.handleChange}
                error={errors.username}
              />
            </div>
            <div className="col-6">
              <Input
                value={data.email}
                onChange={this.handleChange}
                required={true}
                label="Email ID"
                type="text"
                name="email"
                error={errors.email}
              />
            </div>
            <div className="col-6">
              <Input
                value={data.phone_number}
                onChange={this.handleChange}
                required={true}
                label="Phone Number"
                name="phone_number"
                type="text"
                error={errors.phone_number}
              />
            </div>
            <div className="col-6">
              <Input
                value={data.password}
                onChange={this.handleChange}
                required={true}
                label="Password (At least 5 characters)"
                type="password"
                name="password"
                error={errors.password}
              />
            </div>
            <div className="col-6">
              <Input
                value={data.password2}
                onChange={this.handleChange}
                required={true}
                label="Confirm Password"
                name="password2"
                type="password"
                error={errors.password2}
              />
            </div>
            <div className="col-12">
              <Input
                value={data.nationality}
                onChange={this.handleChange}
                required={true}
                label="Nationality"
                name="nationality"
                type="text"
                error={errors.nationality}
              />
            </div>
            <div className="col-12">
              <Input
                value={data.address}
                onChange={this.handleChange}
                label="Address"
                name="address"
                type="text"
              />
            </div>
            <div className="col-12">
              <Input
                value={data.description}
                onChange={this.handleChange}
                label=" Write some information about yourself"
                name="description"
                type="text"
                // error={errors.description}
              />
            </div>

            <div className="d-grid gap-2 col-12">
              <button
                className="btn btn-primary btn-lg btn-block mb-4 mt-2"
                // disabled={this.validate()}
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default Register;
