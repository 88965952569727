import React from "react";
import { Link, Redirect } from "react-router-dom";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import "../App.css";
import Input from "../components/common/input";
import Form from "./common/form";
import { login } from "../services/authService";

// use programmatic navigation form login form to dashboard

// add functionality to show react toast if the user is redierected to different locations due to history
class Log extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {
      email: "",
      passowrd: "",
    },
  };
  schema = {
    email: Joi.string().required().label("Email ID"),
    password: Joi.string().required().label("Password"),
  };
  doSubmit = async () => {
    // call the server;
    try {
      const { data } = this.state;
      const { data: jwt } = await login(data.email, data.password);
      const { id } = this.props.match.params;
      localStorage.setItem("token", jwt);
      window.location.href= `${process.env.REACT_APP_MAIN_DOMAIN}/settoken/${jwt}/${id}`
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Invalid Email Or Password");
      }
    }
  };
  render() {
    if (localStorage.getItem("token")) {
      this.props.history.goBack();
    }
    const { data, errors } = this.state;
    return (
      <div style={{height:"100vh"}}> 
        <div className="center_div" >
          <div
            className="col-12 bg-white w-100 "
            style={{
              border: "0.5px solid gray",
              borderRadius: "10px"
            }}
          >
            <h2 className="pt-3 pb-4">
              {" "}
              <b>Login</b>
            </h2>
            <form onSubmit={this.handleSubmit}>
              <Input
                name="email"
                value={data.email}
                label="Email ID"
                onChange={this.handleChange}
                error={errors.email}
              />
              <Input
                name="password"
                value={data.password}
                label="Password"
                onChange={this.handleChange}
                error={errors.password}
                type="password"
              />
              <div className="text-center">
                <button
                  className="btn btn-primary btn-block mt-4 mb-4 btn-lg"
                  disabled={this.validate()}
                >
                  Login
                </button>
              </div>
            </form>
            <div className=" mt-1 mb-3 row" style={{justifyContent:"space-around"}}>
              <p> <a href={`${process.env.REACT_APP_SUB_DOMAIN}/users/forgotpassword`}>Forgot password</a></p>
              <p>New User? <Link to="/users/register">Register Here</Link></p>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Log;