import http from "./httpService";


export function register(user) {
  return http.post(`${process.env.REACT_APP_API}/users/register`, {
    name: user.name,
    email: user.email,
    username: user.username,
    password: user.password,
    nationality: user.nationality,
    phone_number: user.phone_number,
    address: user.address,
    description: user.description
  });
}