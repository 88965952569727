import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "../common/pagination";
import ListGroup from "../listgroup";
import Posts from "./posts";
import { paginate } from "../../utils/paginate";
import http from "../../services/httpService";
import "../../styles/forum/forum.css";
import FooterTwo from "../footer/FooterTwo";

class Forum extends Component {
  state = {
    allposts: [],
    currentPage: 1,
    pageSize: 6,
    tags: [],
    selectedTag: { _id: "1", name: "All Posts" },
  };
  async componentDidMount() {
    const { data: allposts } = await http.get(
      `${process.env.REACT_APP_API}/posts/`
    );
    const { data: tags } = await http.get(`${process.env.REACT_APP_API}/tags/`);

    this.setState({
      allposts: [...allposts],
      tags: [
        {
          _id: "1",
          name: "All Posts",
        },
        ...tags,
      ],
    });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handlePostDelete = (post) => {};
  handleTagSelect = (tag) => {
    this.setState({ selectedTag: tag, currentPage: 1 });
  };
  getPosts() {
    const { allposts, selectedTag } = this.state;
    const filtered = [];
    for (let i in allposts) {
      const post = allposts[i]; 
      const { tags } = post;
      for (let j in tags) {
        if (tags[j].name === selectedTag.name) {
          filtered.push(post);
          break;
        }
      }
    }
    return filtered;
  }
  render() {
    const { allposts, pageSize, currentPage, tags, selectedTag } = this.state;
    const filtered = selectedTag._id === "1" ? allposts : this.getPosts();
    const posts = paginate(filtered, currentPage, pageSize);
    if (allposts.length === 0)
      return (
        <div className="container d-flex justify-content-center w-100 flex-row loading-container">
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
        </div>
      );
    return (
      <React.Fragment>
        <div className="bg-gray mb-5" style={{minHeight:"80vh"}}>
          <div className="container-lg mt-0">
            <div className="row mb-3">
              <div className="col">
                <div className="d-flex w-100 justify-content-between mt-3 ">
                  <h2>
                    <b className="text-uppercase text-primary">Forum</b>
                  </h2>
                    <Link to="/new-post">
                      <button type="button" class="btn btn-success bg-primary border-primary font-weight-bold">
                        New Post
                      </button>
                    </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 forum-cate">
                <ListGroup
                  items={tags}
                  selectedTag={this.state.selectedTag}
                  onTagSelect={this.handleTagSelect}
                />
              </div>
              <div className="col-sm-9 col-12">
                <Posts posts={posts} onDelete={this.handlePostDelete} />
                <Pagination
                  itemCount={filtered.length}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <FooterTwo></FooterTwo>
      </React.Fragment>
    );
  }
}

export default Forum;
