import React from "react";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import "../App.css";
import Input from "./common/input";
import Form from "./common/form";
import axios from "axios";
import { Redirect } from "react-router-dom";

// use programmatic navigation form login form to dashboard

// add functionality to show react toast if the user is redierected to different locations due to history
class forgotPassword extends Form {
  state = {
    data: { email: ""},
    errors: {
      email: ""
    },
  };
  schema = {
    email: Joi.string().required().label("Email ID")
  };
  deleteBackspace=(str)=>{
    let i=0;
    while(i<str.length)
    {
      if(str[i]==" ")     i++; else break;
    }
    let s="";
    for(let k=i;k<str.length;k++)
    s+=str[k];
    return s;
  }
  doSubmit = async () => {
    // call the server;
    try {
      const { data } = this.state;
      const email=this.deleteBackspace(data.email);
      const obj=await axios.put(`${process.env.REACT_APP_API}/users/forgotpassword`,{email:email});
      if (obj.status==200)
      {
        window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/forgotpassword/emailsent`;
      }
    } catch (error) {
      console.log(error);
        this.setState({errors:{email:"User with this email does not exist"}});
    }
  };
  render() {
    if (localStorage.getItem("token")) {
      this.props.history.goBack();
    }
    const { data, errors } = this.state;
    return (
      <div style={{height:"100vh"}}> 
        <div className="center_div">
          <div
            className="container col-12 bg-white "
            style={{
              border: "0.5px solid gray",
              borderRadius: "10px"
            }}
          >
            <h2 className="pt-3 pb-4">
              <b>Reset Password</b>
            </h2>
            <form onSubmit={this.handleSubmit}>
              <Input
                name="email"
                value={data.email}
                label="Email ID"
                onChange={this.handleChange}
                error={errors.email}
              />
              <div className="text-center">
                <button
                  className="btn btn-primary btn-block mt-4 mb-4 btn-lg"
                  disabled={this.validate()}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

export default forgotPassword;