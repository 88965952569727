import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../contenful";
import ListGroup from "../listgroup";
import Pagination from "../common/pagination";
import "../../styles/business-consulting/postList.css";
import { calculateTimesSinceDate } from "../../utils/calculateTime";
import { AiOutlineClose } from "react-icons/ai";

export default function PostsList() {
  const [postsList, setPostsList] = useState({
    posts: [],
    skip: 0,
    total: 0,
    limit: 0,
  });
  const [ModalOpen,setModalOpen]=useState(false);
  const openModal = () => {
    setModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove('modal-open');
  };
  const PAGE_SIZE = 4 ;
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({
    _id: "0",
    name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
    setCurrentPage(1);
    closeModal();
  };
const [posts,setPosts]=useState([]);
const [postsPerPage,setPostsPerPage]=useState([]);
useEffect(()=>
{
  let tags=[],tagTopush=[{_id:1,name:localStorage.getItem('language') === "en-US"?"Useful news":"Thông tin cần thiết"}];
  if(postsList.posts.length>0)
  {
    postsList.posts.map(post=>{
      return post.tags.map(tag=>{
        if(tags.includes(tag.fields.name)==false)
        {
          tagTopush.push(
            {
              _id:tagTopush.length+1,
              name:tag.fields.name
            });
            tags.push(tag.fields.name);
        }
      })
      })
    }
  setTags(tagTopush);
},[postsList]);
useEffect(
  () =>
    client
      .getEntries({
        content_type: "businessConsultingPost",
        locale: localStorage.getItem("language"),
      })
      .then(({ items, total, limit, skip }) => {
        const posts = items.map(({ fields, sys }) => {
          const { title, postSummary, tags, author, postImage,tag } = fields;
          const { id, createdAt } = sys;
          const timeDiff = calculateTimesSinceDate(
            new Date(createdAt),
            new Date()
          );
          return {
            id: id,
            title: title,
            postImage: {
              title: postImage.fields.title,
              url: postImage.fields.file.url,
            },
            postSummary: postSummary,
            timeDiff: timeDiff,
            tags: tag ? tag : [],
            author: author,
          };
        });
        setPostsList({
          posts: posts,
          total: total,
          skip: skip,
          limit: limit,
        });
      }),
  []
);

useEffect(()=>{
  let posts=[];
  postsList.posts.map(post=>{
    let tags=[];
    post.tags.forEach(element => { 
      tags.push(element.fields.name) 
    })
    if (tags.includes(selectedTag.name)) posts.push(post);
  })
  if (posts.length==0) posts=postsList.posts; 
  setPosts(posts);
},[selectedTag,postsList]);
useEffect(()=>
{
let arr=[];
for(let i=(currentPage-1)*PAGE_SIZE;i<currentPage*PAGE_SIZE;i++)
if (posts[i]==undefined) break; else arr.push(posts[i]);
setPostsPerPage(arr);
},[currentPage,posts]);
  if (postsList.posts.length === 0)
    return (
      <div className="container d-flex justify-content-center w-100 flex-row loading-container">
        <div class="spinner-grow mr-1 ml-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>{" "}
        <div class="spinner-grow mr-1 ml-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>{" "}
        <div class="spinner-grow mr-1 ml-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>{" "}
      </div>
    );
  return postsPerPage.length ? (
    <div className="container-xxl mb-5">
      <div className="col">
        <div className="d-flex mt-4 border-bottom border-secondary flex-column flex-md-row">
          <div className="col-12 col-md-6">
              <p className="text-uppercase"><b>{`${selectedTag.name?selectedTag.name:(localStorage.getItem('language')==="en-US"?"Useful News":"Thông tin cần thiết")}`}</b></p>
          </div>
          <div className="col-12 col-md-6 text-left text-md-right">
              <p>Browse by: <button type="button" class="bg-white text-primary border-0" onClick={openModal}><b>Topic</b></button></p>

          </div>
        </div>
        <div className="d-flex flex-column"  >
          <div className={`${ModalOpen?'modal__category':'d-none'}`}>
            <div className="consulting__category">
              <div className="container">
                <div className="consulting__category__header">
                  <button className="Consulting__category__header__closeButton p-2 float-right" style={{backgroundColor:"initial",border:"none"}}>
                      <AiOutlineClose onClick={closeModal}/>
                  </button>
                  <h2 className="consulting__category__title text-left text-uppercase" style={{fontSize:"20px"}}>Browse by Topic</h2>
                </div>
                <div className="col-10 col-md-4 p-0">
                   <ul className="consulting__category__list">
                   {tags.map((item) => (
                     <li
                       key={item._id} 
                       className={
                         `${item === selectedTag ? "active" : ""} my-2 text-left text-primary consulting__category__item`
                       }
                       style={{cursor:"pointer",fontSize:"28px",lineHeight:"40px",fontWeight:"400"}}
                       onClick={() => handleTagSelect(item)}
                     >
                       {item.name}
                     </li>
                   ))}
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-evenly">
            {postsPerPage.map(
              ({
                id,
                postImage,
                title,
                postSummary,
                timeDiff,
                tags,
                author,
              },key) => (
                <div className="d-flex col-12 col-sm-6 col-xl-3 mt-4" key={key} data-aos={`${(key+1)%3==0?'fade-left':`${(key+1)%3==1?'fade-right':'zoom-in'}`}`}  data-aos-duration="1000">
                  <Link
                    className="post-card list-group-item-action
                                    flex-column align-items-center
                                    "
                    to={`/consulting-post/${id}`}
                  >
                    {/* <div className="post-image col-12 mb-3 p-0">
                      <img
                        src={postImage.url}
                        alt={postImage.title}
                        width="100%"
                      />
                    </div> */}
                    <div className="col-12 d-flex justify-content-between px-4">
                      <div className="col-12 p-0">
                        <h3 className="title__custom mb-2">{title}</h3>
                        {/* {tags.length ? (
                          <div className="tag">
                            {tags.map((el) => (
                              <span className="badge badge-primary mr-3 mt-2 mb-2 pt-2 pb-2 pr-3 pl-3">
                                {el}{" "}
                              </span>
                            ))}
                          </div>
                        ) : null} */}
                        <p className="mt-4 subtitle__custom">{postSummary}</p>
                        {/* <div className="d-flex justify-content-between">
                          <p>Posted by: {author}</p>

                          <p className="time-diff mr-1">{timeDiff}</p>
                        </div> */}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            )}
            <Pagination
              itemCount={posts.length}
              pageSize={PAGE_SIZE}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}