import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import '../../styles/introduction/introduction.css';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
function Pack(props) {
  const { heading, posts } = props;
  console.log(heading, posts);
  const DownScroll = () => {
    const element = document.getElementsByClassName('pack_post')[props.index];
    const currentScrollPosition = element.scrollTop;
    const scrollAmount = 180;
    const newScrollPosition = currentScrollPosition + scrollAmount;
    element.scrollTo({
      top: newScrollPosition,
      behavior: 'smooth',
    });
  };
  const onClick = (event) => {
    event.preventDefault();
    props.onShowModal();
  };
  const UpScroll = () => {
    const element = document.getElementsByClassName('pack_post')[props.index];
    const currentScrollPosition = element.scrollTop;
    const scrollAmount = -180;
    const newScrollPosition = currentScrollPosition + scrollAmount;
    element.scrollTo({
      top: newScrollPosition,
      behavior: 'smooth',
    });
  };
  const renderIntroduction = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <div className="image mb-2">
          <img src={node.data.target.fields.file.url} alt={node.data.target.fields.title} />
          <figcaption className="">{node.data.target.fields.description}</figcaption>
        </div>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="display_content">{children}</p>,
      [BLOCKS.TEXT]: (node, children) => <>{children}</>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <p className="col-12 text-left text-xl-justify">
          <ul className="display_content_ul">{children}</ul>
        </p>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{node.content[0].content[0].value}</li>,
    },
  };
  return (
    <div className="container-fluid mb-2">
      <div className="row position-relative ml-0">
        <div className="col-md-12">
          <h1 className="heading border-bottom border-secondary" id="heading" style={{ color: '#086fb8' }}>
            <b>{heading}</b>
          </h1>
        </div>
        <div className="pack_post pl-0 col-12" id="style-4">
          {posts.map((post, index) => {
            console.log(posts.length, index);
            let { id, title, summary, tagsName } = post;
            return (
              <div className="container-fluid" key={id}>
                <div className="d-flex flex-row ">
                  <div
                    className={`d-flex mr-2 ${index != posts.length -1 ?'line_post':''}`}
                    style={{ height: `${index == posts.length - 1 ? '1px' : ''}` }}
                  >
                    <div class="vr position-relative" style={{ borderLeft: '1px solid black' }}>
                      <div className="position-absolute" style={{ top: '-8px', left: '-6px' }}>
                        <div className="circle">
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/0/14.png"
                            alt="icon"
                            style={{ width: '12px', height: '12px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 post_detail">
                    {/* <div className="tags">{post.tag}</div> */}
                    <Link to={`/pack-post/${id}`}>
                      <div className="mb-2" style={{ color: 'black' }}>
                        <div className="Tags">
                          {tagsName?.map((tag, index) => {
                            return (
                              <span key={index} className="tags">
                                {tag}
                              </span>
                            );
                          })}
                        </div>
                        <div className="body">
                          <h2 className="title">{title}</h2>
                          <p className="text">{documentToReactComponents(summary, renderIntroduction)}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="navigation position-absolute" style={{ bottom: '35%', right: '20px' }}>
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-row">
              <div className="d-flex mr-2">
                <div class="position-relative">
                  <div className={`${window.innerWidth <= 1280 || posts.length <= 2 ? 'd-none' : ''}`}>
                    <div className="circle rounded" style={{ cursor: 'pointer' }} onClick={() => UpScroll()}>
                      <img
                        src="https://icons.veryicon.com/png/o/internet--web/prejudice/up-arrow-5.png"
                        alt="icon"
                        style={{ width: '36px', height: '36px' }}
                      />
                    </div>
                    <div className="circle rounded" style={{ cursor: 'pointer' }} onClick={() => DownScroll()}>
                      <img
                        src="https://icons.veryicon.com/png/o/internet--web/prejudice/down-arrow-5.png"
                        alt="icon"
                        style={{ width: '36px', height: '36px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 text-center introduction">
          {/* {buttonText.toString().toLowerCase().trim(" ")=='connect'?<a href="#" className={`rn-button-style--2 m-auto ${this.props.setColor?"black":"white"}`} onClick={(e)=>this.onClick(e)}>{buttonText}</a>:buttonText.toString().toLowerCase()=='sign up now'?<a href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(window.location.href)}`} className="rn-button-style--2 m-auto">{buttonText}</a>:<a href={`/standard-post/${id}`} className="rn-button-style--2 m-auto">{buttonText}</a>} */}
          {heading != 'Standard Pack' && heading != 'Gói Tiêu Chuẩn' && (
            <a
              href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(window.location.href)}`}
              onClick={(e) => onClick(e)}
              className="rn-button-style--2 m-auto black"
            >
              {localStorage.getItem('language') === 'en-US' ? 'Connect now' : 'Liên hệ '}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Pack;
