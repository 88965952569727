import { Component, Fragment } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import "../styles/Forgotpassword/success.css";
class Successfully extends Component {
  state = { time: 20 };
  render() {
    return (
      <Fragment>
        <div id="master-wrap" className="mt-4">
          <div id="logo-box">
            <div class="animated fast fadeInUp">
              <div class="icon"></div>
              <h1>Thank you</h1>
            </div>

            <div class="notice animated fadeInUp">
              <p class="lead">Your password has been changed</p>
              <a
                class="btn animation"
                href={`${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(process.env.REACT_APP_MAIN_DOMAIN)}`}
              >
                <button style={{ padding: "12px 20px", width: "100%" }}>
                  {" "}
                  Continue
                  <AiOutlineArrowRight
                    fontSize={20}
                    style={{ paddingLeft: "4px" }}
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Successfully;
