import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import './footer.css';
const SocialShare = [
  { Social: <FaFacebookF fill="white" />, link: 'https://www.facebook.com/' },
  { Social: <FaLinkedinIn fill="white" />, link: 'https://www.linkedin.com/' },
  { Social: <FaInstagram fill="white" />, link: 'https://www.instagram.com/' },
  { Social: <FaTwitter fill="white" />, link: 'https://twitter.com/' },
];
const ContactInfo = [
  {
    title: '',
    content: (
      <span>
        <>AVEXCO PTY LTD</>
        <br /> 5, 229 Macquarie Street, Sydney, NSW 2000, Australia
        <br />
        A.C.N 662 604 159
      </span>
    ),
  },
  {
    title: localStorage.getItem('language') === 'en-US' ? 'Phone:' : 'SĐT:',
    content: (<><br/><>AU: 0467658839</><br/><> VN: 093 2722998</></>),
  },
  {
    title: 'Email:',
    content: 'info@avexco.com.au',
  },
  {
    title: 'Website:',
    content: (
      <a href={process.env.REACT_APP_MAIN_DOMAIN} style={{ color: 'white' }}>
        www.avexco.com.au
      </a>
    ),
  },
];
const QuickLink = [
  {
    title: localStorage.getItem('language') === 'en-US' ? 'About Us' : 'Giới thiệu',
    link: `${process.env.REACT_APP_MAIN_DOMAIN}/about`,
  },
  {
    title: localStorage.getItem('language') === 'en-US' ? 'Business Forum' : 'Diễn đàn',
    link: `${process.env.REACT_APP_SUB_DOMAIN}/forum`,
  },
  {
    title: localStorage.getItem('language') === 'en-US' ? 'Business Consulting' : 'Tư vấn',
    link: `${process.env.REACT_APP_SUB_DOMAIN}/consulting`,
  },
];

const FooterTwo = () => {
  return (
    <div className="footer-style-2 ptb--10 bg_image">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="footer-left">
          <div className="inner">
            <span>{localStorage.getItem('language') === 'en-US' ? 'Ready To Do This' : 'Kết nối với chúng tôi'}</span>
            <h2>{localStorage.getItem('language') === 'en-US' ? "Let's get to work" : 'Tư vấn xuất nhập khẩu'}</h2>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-around">
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 spacing my-auto">
            <div className="inner">
              <div className="logo text-center text-sm-left spacing">
                {localStorage.getItem('language') === 'en-US' ? (
                  <p className="opacity-100 text-light text-left" style={{ lineHeight: '36px',fontSize:"24px", marginTop:"-10px" }}>
                   Avexco connects manufacturers with distribution agents in Vietnamese and Australian markets.
                  </p>
                ) : (
                  <p className="opacity-100 text-light text-left" style={{lineHeight: '36px',fontSize:"24px", marginTop:"-10px"}}>
                    Avexco hợp tác kết nối các nhà sản xuất với đại lý phân phối tại thị trường Việt Nam và Australia.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 spacing">
            <div className="text">
              <h4 className="text-center text-sm-left" style={{ fontSize: '32px' }}>
                {localStorage.getItem('language') === 'en-US' ? 'Contact Us' : 'Liên hệ'}
              </h4>
              <ul className="liststyle">
                {ContactInfo.map((val, i) => {
                  return (
                    <li key={i}>
                      <strong>{val.title}</strong> {val.content}
                    </li>
                  );
                })}
              </ul>
              <div className="inner text-center d-block d-sm-none">
                <ul
                  className="social-share rn-lg-size d-flex liststyle col-sm-5 col-lg-4 col-12 m-auto m-lg-0"
                  style={{ width: 'fit-content' }}
                >
                  {SocialShare.map((val, i) => (
                    <li key={i}>
                      <a href={`${val.link}`}>{val.Social}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 col-12 d-none d-lg-block">
            <div className="text ml-auto">
              <h4>{localStorage.getItem('language') === 'en-US' ? 'QUICK LINKS' : 'LIÊN KẾT NHANH'}</h4>
              <ul className="liststyle">
                {QuickLink.map((val, i) => {
                  return (
                    <li key={i}>
                      <a href={`${val.link}`}>{val.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="inner text-lg-right text-center w-100 mt_md--20 mt_sm--20 pr-lg-4">
          <div className="text w-100 col-12" style={{ fontSize: '12px', color: '#DDE6ED' }}>
            Copyright © 2023 Avexco. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
