import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import { PersonCircle, HandThumbsUpFill } from 'react-bootstrap-icons';
import { ToastContainer, toast } from 'react-toastify';
import http from '../../services/httpService';
import PostReply from '../createReply';
import Avatar, { ConfigProvider } from 'react-avatar';
import '../../styles/forum/forum.css';
import { Link } from 'react-router-dom';
import MyVerticallyCenteredModal from './modalTag';

class PostPage extends Component {
  state = {
    post: {
      description: '',
      title: '',
      tags: [],
      author: [],
      upvotes: [],
      views: 0,
    },
    replies: [],
    showReplies: [],
    repliesNum: 3,
    allTags: [],
    isAuthor: false,
    edited: false,
    show: false,
    editCommentId: -2,
  };
  async componentDidMount() {
    const id = this.props.match.params.id;
    const { data: post } = await http.get(`${process.env.REACT_APP_API}/posts/${id}`);
    const { data: replies } = await http.get(`${process.env.REACT_APP_API}/reply/${id}`);
    const { data: tags } = await http.get(`${process.env.REACT_APP_API}/tags/`);
    const showArray = replies.slice(0, this.state.repliesNum);
    this.setState({ ...this.state,post: post, replies: replies, showReplies: showArray,allTags: tags,isAuthor:post.author._id===this.props.user._id?true:false });
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.replies != prevState.replies) {
      const showArray = this.state.replies.slice(0, this.state.repliesNum);
      this.setState({...this.state,showReplies: showArray });
    }
  }

  loadMore() {
    const showArray = this.state.replies.slice(0, this.state.repliesNum + 2);
    const num = this.state.repliesNum + 2;
    this.setState({...this.state,showReplies: showArray, repliesNum: num });
  }
  checkLike() {
    const { user } = this.props;
    const { post } = this.state;
    //console.log(user);
    if (user && post.upvotes && post.upvotes.includes(user._id)) return true;
    else return false;
  }
  checkReplyLike(id) {
    const { replies } = this.state;
    const { user } = this.props;
    if (user) {
      for (let i in replies) {
        if (replies[i]._id === id) {
          if (replies[i].upvotes.includes(user._id)) return true;
        }
      }
    }
    return false;
  }

  handleUpvote = async () => {
    try {
      const { user } = this.props;
      if (user) {
        const { data: post } = await http.put(
          `${process.env.REACT_APP_API}/posts/like/${this.props.match.params.id}`,
          {},
        );
        this.setState({ post: post[0] });
      } else {
        window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(
          window.location.href,
        )}`;
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("You can't upvote your own post!");
      }
    }
  };
  handleReplyUpvote = async (id) => {
    try {
      const { user } = this.props;
      if (user) {
        const replies_old = [...this.state.replies];
        const reply_updated = await http.put(`${process.env.REACT_APP_API}/reply/like/${id}`, {});
        const { data: replies } = await http.get(`${process.env.REACT_APP_API}/reply/${this.props.match.params.id}`);
        console.log('reply', replies);
        this.setState({ replies: replies });
      } else {
        window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/login${encodeURIComponent(
          window.location.href
        )}`;
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("You can't upvote your own reply!");
      }
    }
  };
  handleEdit=()=>{
    const { user } = this.props;
      if (!user) {
        window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/users/login/${encodeURIComponent(
          window.location.href
        )}`;
      }
      else
      {
        this.setState({...this.state,edited:true})
      }
  }
  handleUpdatePost=async()=>{
    try{
      const {data:{post:post}}=await http.put(`${process.env.REACT_APP_API}/posts/update/${this.props.match.params.id}`,{
        title:this.state.post.title,
        description:this.state.post.description,
        tags:this.state.post.tags
      })
      this.setState({...this.state,edited:false,post:post})
    }
    catch(ex){
      console.log(ex)
    }
  }
  handleModal = (stateModal) => {
    this.setState({ ...this.state, show:stateModal});
  };
  updateTags = (tags) => {
    const post = { ...this.state.post };
    post.tags = tags;
    this.setState({ ...this.state, post: post ,show:false});
  };
  setEditCommentId = async (id) => {
    const { data: replies } = await http.get(`${process.env.REACT_APP_API}/reply/${this.props.match.params.id}`);
    this.setState({ ...this.state, editCommentId: id ,replies:replies});
  };
  handleUpdateComment = async (id) => {
    console.log(this.state.replies[id]._id)
    const {data:replies}=await http.put(`${process.env.REACT_APP_API}/reply/update/${this.state.replies[id]._id}`,{reply:this.state.replies[id]})
    const newReplies=[...this.state.replies]
    newReplies[id]=replies
    this.setState({...this.state,replies:newReplies,editCommentId:-2})
  };
  render() {
    const { post, replies, showReplies, repliesNum,allTags,isAuthor,editCommentId} = this.state;
    const { user } = this.props;
    if (post.title === '')
      return (
        <div className="container d-flex justify-content-center w-100 flex-row loading-container">
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{' '}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{' '}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{' '}
        </div>
      );
    return (
      <ConfigProvider colors={['brown', 'green', 'blue']}>
        <div>
          <Link to="/forum">
            <button className="btn btn-outline-primary mt-4 ml-xl-5">Back to Forum</button>
          </Link>
          <ToastContainer />
          <div className="container col-lg-10 shadow-lg p-3 mt-3 pt-5 pt-xl-3 bg-body rounded position-relative">
            {!this.state.edited ? (
              <>
                <h2>{post.title}</h2>
                <p className="mt-4" style={{ color: '#505050' }}>
                  {post.description}
                </p>
              </>
            ) : (
              <>
                <input
                  type="text"   
                  className="form-control w-75"
                  value={post.title}
                  onChange={(e) => {
                    const post = { ...this.state.post };
                    post.title = e.target.value;
                    this.setState({ post: post });
                  }}
                />
                <textarea
                  className="form-control mt-3"
                  value={post.description}
                  onChange={(e) => {
                    const post = { ...this.state.post };
                    post.description = e.target.value;
                    this.setState({ post: post });
                  }}
                  rows="5"
                  cols-="50"
                />
              </>
            )}
            <div className="mt-1">
              {!this.state.edited ? (
                <>
                Related Topics:
                {post.tags && post.tags.map((tag) => <span className="badge badge-primary m-1 p-2">{tag.name}</span>)}
                </>
              ):(
                <>
                 Related Topics:
                {post.tags && post.tags.map((tag) => <span className="badge badge-primary m-1 p-2">{tag.name}</span>)}
                <button className="btn btn-outline-primary ml-3" style={{height:"32px"}} onClick={()=>this.handleModal(true)}>Edit tags</button>
                <MyVerticallyCenteredModal show={this.state.show} onHide={() => this.handleModal(false)} allTags={allTags} post={post} onSave={this.updateTags} />
                </>
              )}
              
              <div className="d-flex w-100 justify-content-between mt-3 mb-3">
                <button
                  className={this.checkLike() ? 'btn btn-primary' : 'btn btn-outline-primary'}
                  onClick={this.handleUpvote}
                >
                  <HandThumbsUpFill className="mr-2" />
                  {(post.upvotes && post.upvotes.length) || 0}
                </button>
                <p>{post.views} Views</p>
              </div>
              <div class="d-flex w-100 justify-content-between" style={{ color: '#505050' }}>
                <div>
                  <Avatar className="mr-2" name={post.author.name} size="25px" round="15px" />
                  Posted by {(post.author && post.author.username) || 0}
                </div>
                <p class="mb-1">
                  <Moment fromNow>{post.time}</Moment>
                </p>
              </div>
            </div>
            {!this.state.edited&&isAuthor?<button className="btn btn-outline-primary mt-3 absolute_top_right mr-4" onClick={this.handleEdit}>Edit Post</button>:isAuthor?<button className="btn btn-outline-primary mt-3 absolute_top_right mr-4" onClick={this.handleUpdatePost}>Save</button>:null}
            
          </div>
          <PostReply user={user} id={this.props.match.params.id} />
          <div className="container col-lg-10 shadow-lg p-3 mt-3 bg-body rounded">Showing {replies.length} replies</div>
          <div>
            {replies &&
              showReplies.map((reply,id) => (
                <div className="container col-lg-9 shadow-lg p-3 mt-2 bg-body rounded">
                  <div>
                    <Avatar className="mr-2" name={reply.author.name} size="35px" round="20px" />
                    Posted by {reply.author.username}
                  </div>
                  {editCommentId==id?
                  <input
                    type="text"
                    className="form-control mt-3"
                    value={reply.comment}
                    onChange={(e) => {
                      const replies = [...this.state.replies];
                      replies[id].comment = e.target.value;
                      this.setState({...this.state, replies: replies });
                    }}/>
                    :<div className="mt-3 ml-1 mb-1">{reply.comment}</div>}
                  <div className="d-flex w-100 justify-content-between mt-3 mb-1">
                    <button
                      className={this.checkReplyLike(reply._id) ? 'btn btn-primary' : 'btn btn-outline-primary'}
                      onClick={() => {
                        this.handleReplyUpvote(reply._id);
                      }}
                    >
                      <HandThumbsUpFill className="mr-2" />
                      {(reply.upvotes && reply.upvotes.length) || 0}
                    </button>
                    <p className="mb-1">
                      <Moment fromNow style={{ color: '#505050' }}>
                        {reply.time}
                      </Moment>
                    </p>
                  </div>
                  {reply.author._id===user._id?
                  editCommentId==id?<button className="btn btn-outline-primary mt-3 absolute_top_right mr-4" onClick={()=>this.handleUpdateComment(id)}>Save</button>:<button className="btn btn-outline-primary mt-3 absolute_top_right mr-4" onClick={()=>this.setEditCommentId(id)}>Edit</button>:null}
                </div>
              ))}
          </div>
          {repliesNum < replies.length && (
            <div className="d-flex justify-content-center">
              <button onClick={() => this.loadMore()} className="btn btn-outline-primary rounded">
                Load more
              </button>
            </div>
          )}
        </div>
      </ConfigProvider>
    );
  }
}

export default PostPage;
