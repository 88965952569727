import Modal from 'react-bootstrap/Modal';
import React from 'react';
import axios from 'axios';
function MyVerticallyCenteredModal(props) {
  const handleContact = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API}/reply/contact`, info).then((res) => {
      alert('Đã gửi thông tin. Cảm ơn bạn đã liên hệ với chúng tôi!');
      props.onHide();
      setInfo({ name: '', surname: '', email: '', phone: '', message: '' });
    });
  };
  const [info, setInfo] = React.useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: '',
  });
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {localStorage.getItem('language') == 'vi-VN' ? 'Liên hệ' : 'Contact'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-lg-offset-2">
              <form id="contact-form" onSubmit={(e) => handleContact(e)} role="form">
                <div class="messages"></div>
                {localStorage.getItem('language') == 'vi-VN' ? (
                  <div class="controls">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_name">Tên *</label>
                          <input
                            id="form_name"
                            value={info.name}
                            onChange={(e) => setInfo({ ...info, name: e.target.value })}
                            type="text"
                            name="name"
                            class="form-control"
                            placeholder="Ex: Cương"
                            required="required"
                            data-error="Firstname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_lastname">Họ *</label>
                          <input
                            id="form_lastname"
                            value={info.surname}
                            onChange={(e) => setInfo({ ...info, surname: e.target.value })}
                            type="text"
                            name="surname"
                            class="form-control"
                            placeholder="Ex: Đỗ Duy"
                            required="required"
                            data-error="Lastname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="form_email">Email *</label>
                          <input
                            id="form_email"
                            type="email"
                            value={info.email}
                            onChange={(e) => setInfo({ ...info, email: e.target.value })}
                            name="email"
                            class="form-control"
                            placeholder="Ex: cuongthtb08022004@gmail.com"
                            required="required"
                            data-error="Valid email is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_phone">Số điện thoại</label>
                          <input
                            id="form_phone"
                            type="tel"
                            value={info.phone}
                            onChange={(e) => setInfo({ ...info, phone: e.target.value })}
                            name="phone"
                            class="form-control"
                            placeholder="Ex: 0969 696 969"
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="form_message">Những vấn đề mà bạn gặp phải khi xuất khẩu hàng hóa</label>
                          <textarea
                            id="form_message"
                            name="message"
                            value={info.message}
                            onChange={(e) => setInfo({ ...info, message: e.target.value })}
                            class="form-control"
                            placeholder="Ex: Tôi gặp phải vấn đề về hải quan khi xuất khẩu hàng hóa."
                            rows="4"
                            required="required"
                            data-error="Please,leave us a message."
                          ></textarea>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="submit" class="btn btn-primary btn-send" value="Send message" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="controls">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_name">FirstName *</label>
                          <input
                            id="form_name"
                            value={info.name}
                            onChange={(e) => setInfo({ ...info, name: e.target.value })}
                            type="text"
                            name="name"
                            class="form-control"
                            placeholder="Ex: John"
                            required="required"
                            data-error="Firstname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_lastname">LastName *</label>
                          <input
                            id="form_lastname"
                            value={info.surname}
                            onChange={(e) => setInfo({ ...info, surname: e.target.value })}
                            type="text"
                            name="surname"
                            class="form-control"
                            placeholder="Ex: Doe"
                            required="required"
                            data-error="Lastname is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="form_email">Email *</label>
                          <input
                            id="form_email"
                            type="email"
                            value={info.email}
                            onChange={(e) => setInfo({ ...info, email: e.target.value })}
                            name="email"
                            class="form-control"
                            placeholder="Ex: cuongthtb08022004@gmail.com"
                            required="required"
                            data-error="Valid email is required."
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="form_phone">Phone Number</label>
                          <input
                            id="form_phone"
                            type="tel"
                            value={info.phone}
                            onChange={(e) => setInfo({ ...info, phone: e.target.value })}
                            name="phone"
                            class="form-control"
                            placeholder="Ex: 0969 696 969"
                          />
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="form_message">What problems are you having with</label>
                          <textarea
                            id="form_message"
                            name="message"
                            value={info.message}
                            onChange={(e) => setInfo({ ...info, message: e.target.value })}
                            class="form-control"
                            placeholder="Ex: I have problems with customs when exporting goods."
                            rows="4"
                            required="required"
                            data-error="Please,leave us a message."
                          ></textarea>
                          <div class="help-block with-errors"></div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <input type="submit" class="btn btn-primary btn-send" value="Send message" />
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
export default MyVerticallyCenteredModal;
