import  { Component } from "react";

class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem("token");
    const { url } = this.props.match.params;
    const URL=decodeURIComponent(url);
    window.location.href = URL;
  }
  render() {
    return (<div className=""></div>)
  }
}

export default Logout;