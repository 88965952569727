import React, { Component } from "react";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import "../App.css";
import Input from "./common/input";
import axios from "axios";
import { Redirect } from "react-router-dom";

// use programmatic navigation form login form to dashboard

// add functionality to show react toast if the user is redierected to different locations due to history
class resetPassword extends Component {
  state = {
    data: { newPassword: "", conFirmnewPassword:""},
    errors: {
      password: "",
      conFirmnewPassword:""
    },
  };
  constructor(props) {
    super(props);
    console.log('initialization');
    const query = new URLSearchParams(this.props.location.search);  
    const resetToken = query.get("resetToken");
    if (resetToken)
    {
        this.token=resetToken;
    }
    else
    {
        window.location.href = `${process.env.REACT_APP_MAIN_DOMAIN}`;
    }
    }
    
  handleChange=(e,type=1)=>{
    if (type==1)
    this.setState({...this.state,data:{...this.state.data,newPassword:e.target.value}});
    else
    this.setState({...this.state,data:{...this.state.data,conFirmnewPassword:e.target.value}});
  }
   handleSubmit=async (e)=>{
    
    e.preventDefault();
    if (this.state.errors.password=="" && this.state.errors.conFirmnewPassword=="")
    {
        try {
            console.log(this.token);
            const res= await axios.put(`${process.env.REACT_APP_API}/users/resetpassword`,{resetToken:this.token,password:this.state.data.newPassword});
            if (res.status==200)
            {
                this.props.history.push({ pathname: '/users/resetpassword/successfully' });
                this.props.history.goForward();
            }
            console.log(res);
        } catch (error) {
                console.log(error);
        }   
    }
    else
    {
        toast.error("Invalid Password Or Password Confirmation Is Wrong");
    }
  }
  handleBlur=(e,type=1)=>{
    if (type==1)
    {
        if (e.target.value.length<5) this.setState({errors:{...this.state.errors,password:"Use at least 5 characters for your password"}})
        else this.setState({errors:{...this.state.errors,password:""}})
    }
    else
    {
        if (e.target.value!==this.state.data.newPassword) 
        this.setState({errors:{...this.state.errors,conFirmnewPassword:"Confirm password doesn't match. Please try again"}});
        else this.setState({errors:{...this.state.errors,conFirmnewPassword:""}});
    }
  }
  render() {
    const { data, errors } = this.state;
    return (
      <div style={{height:"100vh"}}> 
        <div className="center_div">
          <div
            className="container col-12 bg-white "
            style={{
              border: "0.5px solid gray",
              borderRadius: "10px"
            }}
          >
            <h2 className="pt-3 pb-4">
              {" "}
              <b>Reset Password</b>
            </h2>
            <form onSubmit={this.handleSubmit}>
            <div className="form-group">
                <Input
                  value={data.password}
                  onChange={(e)=>this.handleChange(e)}
                  onBlur={(e)=>this.handleBlur(e)}
                  label="New Password"
                  type="password"
                  name="password"
                  error={errors.password}
                />
              </div>
              <div className="form-group">
                <Input
                  value={data.conFirmnewPassword}
                  onChange={(e)=>this.handleChange(e,2)}
                  onBlur={(e)=>this.handleBlur(e,2)}
                  label="Confirm Password"
                  name="password2"
                  type="password"
                  error={errors.conFirmnewPassword}
                />
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary btn-block mt-4 mb-4 btn-lg"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

export default resetPassword;