import { Component } from "react";

class Image extends Component
{
    render()
    {
        return window.innerWidth>1280? (
            
            <div className="Background Image" style={{
                backgroundImage: `url(${this.props.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                paddingTop:"100px",
                paddingBottom:"220px",

            }} >              
            </div>
        ):(
            <div className="Background Image">
                <img src={this.props.image} alt="HeaderImage" style={{width:"100%"}}/>
            </div>
                
        )
    }
}
export default Image;