import React from "react";
import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import Input from "./common/input";
import Form from "./common/form";
import http from "../services/httpService";
import { createpost } from "../services/postCreateService";
import { createtag } from "../services/tagService";

class NewPost extends Form {
  state = {
    data: { title: "", description: "", tags: [] },
    errors: { title: "", description: "", tags: [] },
    tags: [],
    check: false,
    newTag: "",
  };
  schema = {
    title: Joi.string().required().min(10).label("Title"),
    description: Joi.string().required().min(5).label("Description"),
    tags: Joi.array(),
  };
  handleTagChange = (tagID) => {
    let data = this.state.data;
    const newtags = data.tags;
    const index = newtags.indexOf(tagID);
    if (index === -1) newtags.push(tagID);
    else newtags.splice(index, 1);
    data = {
      title: data.title,
      description: data.description,
      tags: newtags,
    };
    console.log(data);
    this.setState({ data });
  };
  handleTagInputChange = (event) => {
    event.preventDefault();
    this.setState({ newTag: event.target.value });
  };

  handleTagSubmit = async (name) => {
    const { response } = await createtag(name);
    window.location.reload();
  };
  async componentDidMount() {
    let tags = await http.get(`${process.env.REACT_APP_API}/tags/`);
    try {
      this.setState({ tags: tags.data });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error("Post Validation Failed!");
      }
    }
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const { response } = await createpost(data);
      console.log(response);
      window.location = "/forum";
    } catch (ex) {}
  };
  render() {
    const { data, errors, tags } = this.state;
    const isAdmin = this.props.user ? this.props.user.isAdmin : false;
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="container-lg">
          <h1 className="text-center m-2">Create a New Discussion</h1>
          <div
            className="container m-4 p-4 rounded"
            style={{ backgroundColor: "#F1F1F1" }}
          >
            <form onSubmit={this.handleSubmit} className="form">
              <div>
                <label htmlFor="title">
                  <h6>
                    {" "}
                    <b>Title</b>
                  </h6>
                </label>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    name="title"
                    class="form-control"
                    value={data.title}
                    onChange={this.handleChange}
                  />
                </div>
                {this.state.check && errors.title && (
                  <div
                    style={{
                      color: "#dc3545",
                    }}
                  >
                    {errors.title}
                  </div>
                )}
              </div>

              <div className="form-group mt-3">
                <label htmlFor="description">
                  <h6>
                    {" "}
                    <b>Description</b>
                  </h6>
                </label>
                <textarea
                  value={data.description}
                  onChange={this.handleChange}
                  name="description"
                  type="description"
                  id="description"
                  className="form-control"
                />
                {this.state.check && errors.description && (
                  <div
                    style={{
                      color: "#dc3545",
                    }}
                  >
                    {errors.description}
                  </div>
                )}
              </div>
              <div className="form-group mt-3">
                <label htmlFor="tags">
                  <h6>
                    {" "}
                    <b>Topic Tags</b>
                  </h6>
                </label>
                <br />
                {tags.map((tag) => (
                  <React.Fragment>
                    <label className="ml-4 mr-4">
                      <input
                        key={tag._id}
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => this.handleTagChange(tag._id)}
                      />
                      {tag.name}
                    </label>
                  </React.Fragment>
                ))}
                {errors.tags && <div className="alert-info">{errors.tags}</div>}
              </div>

              {isAdmin ? (
                <>
                  <label>
                    <h6>
                      {" "}
                      <b>Create Tag</b>
                    </h6>
                  </label>
                  <div className="d-flex w-50">
                    <input
                      type="text"
                      name="tag"
                      value={this.state.newTag}
                      className="form-control"
                      style={{ width: "60%" }}
                      onChange={this.handleTagInputChange}
                    />
                    <button
                      className="btn btn-primary ml-4"
                      onClick={() => this.handleTagSubmit(this.state.newTag)}
                    >
                      Add Tag
                    </button>
                  </div>
                </>
              ) : null}

              <div className="text-center">
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => this.setState({ check: true })}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewPost;
