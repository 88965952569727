import React, { useEffect, useState } from "react";
import { client } from "../../contenful";
import { BLOCKS,MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import "../../styles/business-consulting/consultingPost.css";
import FooterTwo from "../footer/FooterTwo";
import { Link } from "react-router-dom";
export default function PostDetail() {
  const { id } = useParams();
  const [post, setPost] = useState();
  useEffect(() => {
    if(localStorage.getItem("token"))
    {
        client.getEntry(id,{locale: localStorage.getItem('language')}).then(({ fields }) => {
            console.log(fields)
          const {  contentDetail } = fields;
          setPost({
            contents: contentDetail,
          });
        });
    }
    else
    {
        window.location.href=`/users/login/${encodeURIComponent(window.location.href)}`;
    }
  }, []);

  const richTextDocument = post ? post.contents : null;
  const renderIntroduction = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <div className="image mb-2 col-12">
          <img
            src={node.data.target.fields.file.url}
            alt={node.data.target.fields.title}
            className="img-fluid w-100 h-100"
          />
          <figcaption className="text-center">
            {node.data.target.fields.title}
          </figcaption>
        </div>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="col-12 display_content">{children}</p>
      ),
      [BLOCKS.TEXT]: (node, children) => <>{children}</>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <p className="col-12 text-left text-xl-justify">
          <ul className="display_content_ul">{children}</ul>
        </p>
      ),
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li >{node.content[0].content[0].value}</li>
      ),
      [BLOCKS.HEADING_1]: (node, children) => {
        return (<h2 className="text-center text-uppercase mb-5">{children}</h2>)
      },
      [BLOCKS.HEADING_2]: (node, children) => {
          return (<h2 className="text-center text-uppercase mb-5">{children}</h2>)
      }
    },
  };
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <React.Fragment>
      <div className="consulting__post__backButton">
              {/* <a href="/consulting">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" id="arrow"><path fill-rule="evenodd" d="M10.634.292a1.063 1.063 0 0 0-1.464 0L.607 8.556a1.95 1.95 0 0 0 0 2.827l8.625 8.325c.4.385 1.048.39 1.454.01a.975.975 0 0 0 .01-1.425l-7.893-7.617a.975.975 0 0 1 0-1.414l7.83-7.557a.974.974 0 0 0 0-1.413"></path></svg>
                <span>Back to Consulting Posts Page</span>
              </a> */}
              <Link to="/consulting">
                <button className="btn btn-outline-primary mt-4 ml-2 ml-sm-5">Back to Consulting</button>
              </Link>
            </div>
      {richTextDocument ? (
        <div className="col-12 mt-5">
          <div className="container__consultingPost">
            <div className="row">
              <div className="col-12 ml-auto">
                {documentToReactComponents(richTextDocument, renderIntroduction)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container d-flex justify-content-center w-100 flex-row loading-container">
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
        </div>
      )}
      <FooterTwo></FooterTwo>
    </React.Fragment>
  );
}