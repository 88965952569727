import Modal from 'react-bootstrap/Modal';
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useState } from 'react';
import { process } from 'joi-browser';
function MyVerticallyCenteredModal(props) {
    const [tags,setTags]=useState([
        ...props.post.tags
    ]);
    const removeTag=(tag)=>{
        let temp=tags.filter((t)=>t._id!==tag._id);
        setTags(temp);
    }
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Tags
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
            {props.allTags.map((tag)=>{
                let flag=false;
                for(let i in tags){
                    if(tags[i]._id===tag._id){
                        flag=true;
                        break;
                    }
                }
                return(
                    flag?(
                        <ListGroup.Item key={tag._id} className='row d-flex align-items-center' onClick={()=>removeTag(tag)}><p className='mb-0 col-10'>{tag.name}</p>
                            <img src={`${process.env.REACT_APP_SUB_DOMAIN}/assets/img/tick.png`} alt="checked" />
                        </ListGroup.Item>
                    ):<ListGroup.Item key={tag._id} className='row d-flex align-items-center' onClick={()=>setTags([...tags,tag])}><p className='mb-0 col-10'>{tag.name}</p></ListGroup.Item>
                )
            })}
        </ListGroup>
      </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-primary' onClick={()=>props.onSave(tags)}>Save</button>
        </Modal.Footer>
    </Modal>
  );
}
export default MyVerticallyCenteredModal;
