import React, { useEffect, useState } from "react";
import { client } from "../../contenful";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useParams } from "react-router-dom";
import "../../styles/business-consulting/consultingPost.css";
import FooterTwo from "../footer/FooterTwo";
import { Link } from "react-router-dom";
export default function ConsultingPost() {
  const { id } = useParams();
  const [post, setPost] = useState();
  useEffect(() => {
    client.getEntry(
      id,{
        locale: localStorage.getItem("language"),
      }
    ).then(({ fields, sys }) => {
      const { title, postImage, postSummary, tags, author, contents } = fields;
      const { id, createdAt } = sys;
      setPost({
        id: id,
        title: title,
        postImage: {
          title: postImage.fields.title ? postImage.fields.title : null,
          description: postImage.fields.description
            ? postImage.fields.description
            : null,
          file: postImage.fields.file,
        },
        postSummary: postSummary,
        createdAt: createdAt,
        tags: tags ? tags : [],
        author: author,
        contents: contents,
      });
    });
  }, []);

  const richTextDocument = post ? post.contents : null;
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <div className="image mb-2 text-center">
          <img
            src={node.data.target.fields.file.url}
            alt={node.data.target.fields.title}
          />
          <figcaption className="font-italic">{node.data.target.fields.description}</figcaption>
        </div>
      ),
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-justify">{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => {
      return (<h2 className="text-center font-weight-bold mb-5 consulting_post_title">{children}</h2>)
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (<h2 className="text-center font-weight-bold mb-5">{children}</h2>)
    },
    },
  };
  return (
    <React.Fragment>
      <div className="consulting__post__backButton">
              <Link to="/consulting">
                <button className="btn btn-outline-primary mt-4 ml-2 ml-sm-5">Back to Consulting</button>
              </Link>
            </div>
      {richTextDocument ? (
        <div className="col-12">
          <div className="container__consultingPost">
            <div className="row">
              <div className="col-12">
                {documentToReactComponents(richTextDocument, options)}
              </div>
              <div className="col-12 text-right">
                <p className="text-muted font-weight-bold mt-2"><i>Tác giả: {post.author}</i></p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container d-flex justify-content-center w-100 flex-row loading-container">
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
          <div class="spinner-grow mr-1 ml-1" role="status">
            <span class="sr-only">Loading...</span>
          </div>{" "}
        </div>
      )}
      <FooterTwo></FooterTwo>
    </React.Fragment>
  );
}