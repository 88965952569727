import React, { Component } from "react";

class Profile extends Component {
  render() {
    const { user } = this.props;
    console.log("user", user);
    return (
      <div>
        <h1>Profile</h1>
        <p>Name: {user.name}</p>
        <p>Phone: {user.phoneNumber}</p>
        <p>Address: {user.address}</p>
        <p>Nationality: {user.nationality}</p>
      </div>
    );
  }
}

export default Profile;
